<script>
  import { Data, get_lang } from "@stores/app_store.js";
  import { router } from "yrv";
  // $:console.log($router);
  let langs = $Data.langs[0];
</script>

{#if $router.path == "/"}
  <div class="center">
    <h2>{@html langs.arithmosMitroou}</h2>
  </div>
{:else if $router.path.includes("/player")}
  <div class="center">
    <h2>{@html langs.arithmosMitroou}</h2>
  </div>
{:else}
  <div class="center __ popup">
    <h2>{@html langs.Mfixtechnic}</h2>
    <h2>{@html langs.arithmosMitroou}</h2>
    <h1>G&F SCOUTER</h1>
    <h4>Football Players management</h4>
  </div>
{/if}

<style lang="scss">.center{color:#fff;font-size:22px;overflow:hidden;height:50px;text-shadow:0 0 19px #000;text-align:center;width:100%;margin:0;transition:height 1s;position:relative;top:5px;background-size:100% 600%;background-position:50%}@media only screen and (max-width:830px) and (min-width:200px){.center{margin:-10px 0;top:0}}.center h1{font-size:65px;margin:30px 0}@media only screen and (max-width:830px) and (min-width:200px){.center h1{font-size:46px;margin:24px 0}}.center h2{font-size:75%}@media only screen and (max-width:830px) and (min-width:200px){.center h2{font-size:3vw;margin-top:0}}@media only screen and (max-width:830px) and (min-width:200px){:global(.blueslogan){height:60px}}@media only screen and (max-width:830px) and (min-width:200px){:global(.blueslogan),:global(.blueslogan) .center{margin:0!important;padding:0!important;display:flex;align-content:center;justify-content:center;align-items:center}:global(.blueslogan) .center{height:30px}:global(.blueslogan) .center h2{margin:0!important;padding:0!important;font-size:3.4vw}}:global(.blueslogan) .center h2{color:#2976c7;text-shadow:0 0 #000;font-family:Comfortaa,sans-serif}.__{height:160px}</style>
