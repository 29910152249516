<script>
  import { Data, get_lang } from "@stores/app_store.js";
  import { onMount } from "svelte";
  let flag;
  onMount(() => {
    switch (get_lang()) {
      case "EL":
        flag.src = "https://www.gffootballplayers.com/images/flags/greece.png";
        break;
      case "EN":
        flag.src = "https://www.gffootballplayers.com/images/flags/english.png";
        break;
      case "SP":
        flag.src = "https://www.gffootballplayers.com/images/flags/spain.png";
        break;
    }
  });
</script>

<style lang="scss">.languagepicker{display:inline-block;padding:0;max-height:36px;max-width:50px;overflow:hidden;position:absolute;top:55px;right:5px;z-index:999;transition:max-height .2s ease-out,max-width .2s,background-color .5s ease-out}@media screen and (max-width:1025px) and (min-width:769px){.languagepicker{top:110px}}.languagepicker:hover{background-color:#fff;max-height:500px;max-width:500px}.languagepicker li{display:flex;align-items:center;padding:4px;cursor:pointer}.languagepicker li:first-child{transition:height .6s,opacity .2s;opacity:1;height:38px}.languagepicker li img{width:41px;margin:0 5px 0 0;transform:scale(.8)}.languagepicker li:hover{background-color:hsla(0,0%,100%,.8)}.languagepicker:hover li{color:#1764b2!important}.languagepicker:hover li:first-child{opacity:0;height:0}</style>

<ul class="languagepicker roundborders large">
  <li>
    <img
      src="https://www.gffootballplayers.com/images/flags/greece.png"
      alt="Greek "
      bind:this={flag} />
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('EL');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/greece.png"
      alt="Greek " />
    Greek
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('SP');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/spain.png"
      alt="Spanish" />
    Spainish
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('EN');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/english.png"
      alt="English" />
    English
  </li>

</ul>
