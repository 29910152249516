<script>
  import Topnews from "./topnews.svelte";
  import News from "./news.svelte";
  import Video from "./video.svelte";
</script>

<svelte:head>
  <title>GFF News</title>
</svelte:head>

<Video />

<div class="fullWidth inner">
  <div class="wrapper_">
    <div class="fullWidth section1">
      <Topnews />
    </div>
    <div class="fullWidth section2">
      <News />
    </div>
  </div>
</div>

<style lang="scss">.inner{width:100%;z-index:9;bottom:150px;min-height:230px}.inner,.inner .wrapper_{background:#f5f5f5;position:relative}.inner .wrapper_{display:flex;flex-wrap:wrap;justify-content:center;padding-bottom:30px;bottom:0;max-width:1350px;left:50%;transform:translateX(-50%)}.inner .wrapper_ .info{text-align:center;width:90%}.fullWidth.section1{top:-200px;border:10px solid #fff;background-color:#fff}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section1{top:-100px}}.fullWidth.section1{height:400px;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section1{height:400px}}.fullWidth.section2{height:100%}</style>
