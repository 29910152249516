<script>
  import Header from "@components/header/header.svelte";
  import Container from "@components/container/container.svelte";
  import Body from "./components/body.svelte";
  import { fade, fly } from "svelte/transition";
  import { onMount } from "svelte";

  const urlParams = new URLSearchParams(window.location.search);
  let success = null;

  onMount(() => {
    success = urlParams.get("success");
    setTimeout(() => {
      success = null;
    }, 3000);
  });
</script>

<svelte:head>
  <html lang="el" />
</svelte:head>
{#if success != null}
  <div class="Success swap" out:fly={{ y: -1000, duration: 500 }}>
    <i class="far fa-envelope appearleft" />
    <br />
    <i class="fas fa-check popupslow" />
  </div>
{/if}
<Header />
<Container {Body} />

<style lang="scss">.Success{position:fixed;top:calc(50% - 200px);left:calc(50% - 200px);z-index:999;width:400px;line-height:1;height:400px;background:green;color:#fff;font-size:160px;border-radius:50%;text-align:center;box-shadow:0 0 10px #000;padding:40px 0}</style>
