<script>
  import Playerslider from "./playerslider.svelte";
  import Federations from "./federations.svelte";
  import Whoweare from "./whoweare.svelte";
  import About from "./about.svelte";
  import Secvices from "./services.svelte";
  import Questions from "./questions.svelte";
  import Contact from "./contact.svelte";
  import TopNews from "@views/news/components/topnews.svelte";
  import { onMount } from "svelte";
  import Video from "@views/index/components/video.svelte";

  onMount(() => {
    document.querySelector("#Main").scrollTop = 0;
  });
</script>

<div class="fullWidth sectionvideo">
  <Video />
</div>

<div class="fullWidth section0">
  <About />
</div>
<div class="fullWidth section2">
  <Federations />
</div>

<!-- <div class="fullWidth section0">
  <Secvices />
</div> -->

<div class="fullWidth section4">
  <Whoweare />
</div>

<div class="fullWidth section1">
  <Playerslider />
</div>

<div class="fullWidth section5">
  <Questions />
</div>
<div class="fullWidth section3">
  <TopNews />
</div>
<div class="fullWidth section6">
  <Contact />
</div>

<style lang="scss">.fullWidth.sectionvideo{background-color:#f5f5f5;height:100vh;max-height:90vh;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.sectionvideo{max-height:80vh}}.fullWidth.section0{background-color:#f5f5f5;height:650px;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section0{height:595px}}.fullWidth.section6{background-color:#f5f5f5;height:950px;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section6{height:1100px}}.fullWidth.section5{background-color:#f5f5f5;height:250px}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section5{height:270px}}.fullWidth.section1{height:620px;position:relative}.fullWidth.section1 .Background{overflow:hidden}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section1{height:520px}}.fullWidth.section2{height:170px;background-color:#22396e}.fullWidth.section3{height:670px;background:#e3ecec;padding:20px 11%}@media only screen and (max-width:830px) and (min-width:200px){.fullWidth.section3{height:470px;background:#f5f5f5;padding:0;overflow:hidden}}</style>
