<script>
  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
</script>

<div class="fullWidth inner">
  <video autoplay="true" nocontrols loop muted>
    <source
      src="https://gffootballplayers.com/images/founder_letters.mp4"
      type="video/mp4"
    />
    <!-- <source src={langs.RegisterNow?.Videolink} type="video/mp4" /> -->
    Your browser does not support HTML video.
  </video>
 
</div>

<style lang="scss">.inner{display:flex;flex-wrap:wrap;position:relative;top:0;left:49.9%;transform:translateX(-50%);justify-content:center;text-align:center;align-content:center;align-items:center;background-position:bottom;height:60vh;width:100vw;background-size:cover;background-color:#005a9e;z-index:0;flex-direction:column}@media only screen and (max-width:830px) and (min-width:200px){.inner{top:0;height:60vh;width:100vw}.inner:before{bottom:-86px;height:125px}}.inner iframe,.inner video{position:absolute;width:100%;height:110%;top:0;left:0;-o-object-fit:cover;object-fit:cover;background:linear-gradient(90deg,#2365b2,#461bbb)}@media only screen and (max-width:830px) and (min-width:200px){.inner iframe,.inner video{top:161px;left:0;width:100%;height:43%;-o-object-fit:cover;object-fit:cover}}.descriptionQ{font-size:15.2px;line-height:21px}@media only screen and (max-width:830px) and (min-width:200px){.descriptionQ{font-size:13.2px;line-height:16px;z-index:9}}:global(.bottomPart svg){margin:0 20px!important;height:130px}@media only screen and (max-width:830px) and (min-width:200px){:global(.bottomPart svg){margin:0 5px!important;height:100px}}.headPart{display:flex;color:#fff;justify-content:space-around}@font-face{font-family:Open Sans;font-style:normal;font-weight:400;src:local("Open Sans"),local("OpenSans"),url(http://themes.googleusercontent.com/static/fonts/opensans/v5/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format("woff")}.descriptionQ,.title{width:100%;max-width:850px;color:#fff;top:20vh;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.descriptionQ,.title{width:100%;top:0!important}}.more{cursor:pointer;background:linear-gradient(90deg,#2365b2,#461bbb);padding:10px 25px;z-index:99;color:#fff;font-size:20px;font-weight:99;border-radius:50px;border-bottom-right-radius:50px;border-top-right-radius:50px;width:320px;margin:0 auto;transform:scale(.8)}.more :global(svg){margin-left:30px}</style>
