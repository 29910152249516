<script>
  import { Data } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let social = $Data.social;
</script>

<div class="Footer">
  <div class="wrapper">
    <div class="container">
      <!-- <img
        src="https://www.gffootballplayers.com/images/footer.png"
        alt="FOOTER"
      /> -->
      <a
        href="https://agents.fifa.com/license-validation?hash=bc2e1e93251f3c430df9117b37826e994625d3a8e3c4358b55a0f2f360b23fac"
        target="_black"
      >
        <div class="qrcode">
          <img
            alt="qrcode"
            src="https://gffootballplayers.com/images/qrcode.jpg"
            width="100%"
          />
        </div>
      </a>
    </div>
  </div>
  <div class="wrapper">
    <div class="container">
      {#if social.facebook.active}
        <a href={social.facebook.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-facebook-f" />
          </div>
        </a>
      {/if}
      {#if social.twitter.active}
        <a href={social.twitter.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-twitter" />
          </div>
        </a>
      {/if}
      {#if social.youtube.active}
        <a href={social.youtube.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-youtube" />
          </div>
        </a>
      {/if}
      {#if social.linkedin.active}
        <a href={social.linkedin.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-linkedin-in" />
          </div>
        </a>
      {/if}
      {#if social.instagram.active}
        <a href={social.instagram.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-instagram" />
          </div>
        </a>
      {/if}
      {#if social.tm.active}
        <a href={social.tm.url} target="_blank">
          <div class="socialBox">
            <img
              src="https://www.gffootballplayers.com/images/social/tm.png?v={$Data.Ver}"
              alt="transfermarkt"
              style="height: 25px;"
            />
          </div>
        </a>
      {/if}
    </div>
  </div>
  <div class="wrapper">
    <div class="container" style="    flex-wrap: wrap;">
      <a href="https://www.fifa.com/" target="_black">
        <div class="socialBox">
          <img
            src="https://www.gffootballplayers.com/sponsors/ff.png"
            alt="FIFA"
            width="49px"
          />
        </div>
      </a>

      <a href="https://www.uefa.com/" target="_black">
        <div class="socialBox">
          <img
            src="https://www.gffootballplayers.com/sponsors/uefa.png"
            alt="UEFA"
            width="40px"
          />
        </div>
      </a>

      <a href="https://www.the-afc.com/en/home.html" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/AFC-Logo.png" alt="AFC" width="40px" />
        </div>
      </a>

      <a href="https://www.cafonline.com/" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/CAF.svg" alt="CAF" width="40px" />
        </div>
      </a>

      <a href="https://www.concacaf.com/" target="_black">
        <div class="socialBox">
          <img
            src="/images/sponsors/Concacaf_logo.svg.png"
            alt="Concacaf"
            width="40px"
          />
        </div>
      </a>

      <a href="https://www.conmebol.com/" target="_black">
        <div class="socialBox">
          <img
            src="/images/sponsors/Conmebol_2017.webp"
            alt="conmebol"
            width="40px"
          />
        </div>
      </a>

      <a href="https://www.oceaniafootball.com/" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/OFC.png" alt="OFC" width="40px" />
        </div>
      </a>
    </div>
  </div>
  <div class="wrapper">
    <div class="container">
      © Gffootballplayers.com - (2024) - ({langs.arithmosMitroou})
    </div>
  </div>
</div>

<style lang="scss">.qrcode{width:150px;margin:30px -19px -19px;background:#fff;border-radius:100%;overflow:hidden;padding:10px;border:6px solid #4075c9}@media only screen and (max-width:830px) and (min-width:200px){.qrcode{width:150px;margin:26px 26px 10px}}.Footer{position:absolute;width:100%;bottom:0;left:0;display:flex;flex-flow:wrap;justify-content:center;background:linear-gradient(6deg,#374cc1,rgba(5,122,184,0));border-top:45px solid #fff;-webkit-backdrop-filter:blur(11px);backdrop-filter:blur(11px)}@media only screen and (max-width:830px) and (min-width:200px){.Footer{height:520px}}.container,.wrapper{width:100%;display:flex;justify-content:center}.container{text-align:center;color:#f5f5f5;padding:6px;align-items:center;height:100%;float:right}.container .socialBox{width:60px;height:60px;transition:background 1s;display:flex;align-items:center;justify-content:center}.container .socialBox:hover{background:#fff;transition:color 1s}.container .socialBox:hover i.fab{color:#1034a6;font-size:18px}.container .socialBox i.fab{color:#fff;font-size:18px}</style>
