<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let trainers = $Data.trainers;
  let maxheight = 0;
</script>

<div class="fullWidth inner">
  {#each trainers as item, i}
    {#if item._id == $router.params.slug}
      <div class="item">
        <div class="container">
          <div class="box popup" id="box_{i}">
            <div
              class="box_img"
              style="background:url('https://www.gffootballplayers.com/{item.Big_Image
                ? item.Big_Image.path
                : ''}')"
            >
              <img
                src="https://www.gffootballplayers.com/{item.Preview_Image
                  ? item.Preview_Image.path
                  : ''}"
                width="40%"
                align="left"
                alt="image"
                class="Pimage"
              />
              <div class="box_head">{item["Title" + get_lang()]}</div>
            </div>

            <div class="box_desc">
              {@html item["Description" + get_lang()]}
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">.inner,.inner .container{width:100%}.inner .container .box{background:#fff;overflow:hidden}.inner .container .box:hover{box-shadow:0 0 1px 5px rgba(61,158,215,.63)}.inner .container .box .box_img{position:relative;width:100%;height:450px;background-position:top;background-size:100% 100%;float:left}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_img{background-size:cover}}.inner .container .box img.Pimage{width:300px;border-radius:500px;height:300px;position:absolute;border:10px solid #fff;-o-object-fit:cover;object-fit:cover;top:50px;left:calc(50% - 150px)}.inner .container .box .box_head{width:100%;bottom:0;background:rgba(0,0,0,.5);padding:20px;font-size:38px!important}.inner .container .box .box_head,.inner .container .box .box_time{font-weight:900;position:absolute;color:#f5f5f5;margin:0!important;text-align:center}.inner .container .box .box_time{width:50px;height:100px;left:0;top:0;z-index:9;background:linear-gradient(90deg,#4b60db,#0060dd);padding:27px 5px;font-size:15px!important}.inner .container .box .box_desc{font-size:16px;overflow:hidden;float:left;width:90%;overflow-wrap:break-word;margin:60px}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc{font-size:14px;width:90%;margin:16px}}.inner .container .box .box_desc img{margin:25px}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc img{margin:22px 10px 0 0;width:100%}}</style>
