<script>
  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let fixtechnic = $Data.fixtechnic[0];
  import { onMount } from "svelte";
  let TopPart, header, body;

  var observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.intersectionRatio !== 0) {
        header.classList.add("popup");
        body.classList.add("popupslow");
      }
    },
    {
      threshold: [0, 1]
    }
  );
  onMount(() => {
    observer.observe(TopPart);
  });
</script>

<style lang="scss">.inner{display:flex;flex-wrap:wrap;position:relative;justify-content:center;padding-bottom:30px}.MiddlePart{border-bottom-left-radius:10px!important}.LeftPart{border-bottom-right-radius:10px!important}.RightPart{border-radius:0!important}.LeftPart,.MiddlePart,.RightPart{cursor:pointer;background:#fff;box-shadow:0 0 20px rgba(0,0,0,.17);height:400px;border-radius:40px;width:calc(33% - 20px);margin:10px;position:relative}@media only screen and (max-width:830px) and (min-width:200px){.LeftPart,.MiddlePart,.RightPart{width:calc(100% - 20px)}}.LeftPart .Back,.MiddlePart .Back,.RightPart .Back{background-position:top;background-repeat:no-repeat;background-size:cover;position:absolute;z-index:1;height:200px;width:100%}.LeftPart .photo,.MiddlePart .photo,.RightPart .photo{background-position:50%;background-repeat:no-repeat;background-size:100%;background-color:#0c2340;position:absolute;left:calc(50% - 100px);top:calc(50% - 145px);height:200px;width:200px;z-index:2;border-radius:52%;border:10px solid #fff}.LeftPart .desc,.MiddlePart .desc,.RightPart .desc{position:absolute;z-index:2;padding:10px;font-size:14px;line-height:16px;top:calc(50% + 38px);max-height:107px;overflow:hidden}.LeftPart .desc :global(h1),.MiddlePart .desc :global(h1),.RightPart .desc :global(h1){font-size:16px!important}.LeftPart .title,.MiddlePart .title,.RightPart .title{position:absolute;z-index:3;padding:10px;font-size:15px;font-weight:999;top:0;color:#f5f5f5;text-shadow:0 0 8px #000;background:linear-gradient(90deg,#1d55b2,#3289d8)}.LeftPart .more,.MiddlePart .more,.RightPart .more{position:absolute;bottom:12px;right:30px;z-index:10;color:#fff;background:linear-gradient(90deg,#4b60db,#0060dd);font-size:14px;text-shadow:0 0 10px #000;font-weight:100;padding:8px;border-radius:6px}</style>

<div class="fullWidth inner">

  <div class="TopPart" bind:this={TopPart}>
    <div class="header" bind:this={header}>{langs.Mfix_cv}</div>
    <div class="body" bind:this={body}>
      {@html fixtechnic.cvWord}
    </div>

  </div>
  {#each fixtechnic.cv as item, i}
    {#if i % 3 == 0}
      <div
        class="LeftPart appearleft"
        on:click={() => {
          navigateTo('/agents/' + i);
        }}>

        <div
          class="photo"
          style="background-image:url(https://www.gffootballplayers.com/{item.value.image.path})" />
        <div class="word">
          <div class="title">{item.value['title' + get_lang()]}</div>
          <div class="desc">
            {@html item.value['description' + get_lang()]}
          </div>
          <div class="more">{langs.Ymorebutton}</div>
        </div>
      </div>
    {:else if i % 2 == 0}
      <div
        class="MiddlePart appearleft"
        on:click={() => {
          navigateTo('/agents/' + i);
        }}>

        <div
          class="photo"
          style="background-image:url(https://www.gffootballplayers.com/{item.value.image.path})" />
        <div class="word">
          <div class="title">{item.value['title' + get_lang()]}</div>
          <div class="desc">
            {@html item.value['description' + get_lang()]}
          </div>
          <div class="more">{langs.Ymorebutton}</div>
        </div>
      </div>
    {:else}
      <div
        class="RightPart appearleft"
        on:click={() => {
          navigateTo('/agents/' + i);
        }}>

        <div
          class="photo"
          style="background-image:url(https://www.gffootballplayers.com/{item.value.image.path})" />
        <div class="word">
          <div class="title">{item.value['title' + get_lang()]}</div>
          <div class="desc">
            {@html item.value['description' + get_lang()]}
          </div>
          <div class="more">{langs.Ymorebutton}</div>
        </div>
      </div>
    {/if}
  {/each}

</div>
