<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let news = $Data.News.slice().sort((a, b) => b._created - a._created);
  let maxheight = 0;

  const getyear = (unixtimestamp) => {
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp * 1000);
    // Year
    var year = date.getFullYear();
    return year;
  };

  const getday = (unixtimestamp) => {
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp * 1000);
    var day = date.getDate();
    return day;
  };

  const getmonth = (unixtimestamp) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(unixtimestamp * 1000);
    var month = months_arr[date.getMonth()];
    return month;
  };
</script>

<div class="fullWidth inner">
  {#each news as item, i}
    {#if item._id == $router.params.slug}
      <div class="item">
        <div class="container">
          <div class="box popup" id="box_{i}">
            <div class="box_time">
              {getday(item._modified)}
              <br />
              {getmonth(item._modified)}
              <br />
              {getyear(item._modified)}
            </div>
            {#if item.Tropos_pou_gemizi_to_background && item.Tropos_pou_gemizi_to_background != ""}
              <div
                class="box_img"
                style="background-image:url('https://www.gffootballplayers.com/{item
                  .Big_Image.path}');
                    background-position: 0 -50px; background-size: {item.Tropos_pou_gemizi_to_background}!important;
                    "
              >
                <div class="box_head">{item.Title}</div>
              </div>
            {:else}
              <div
                class="box_img"
                style="background-image:url('https://www.gffootballplayers.com/{item
                  .Big_Image.path}');"
              >
                <div class="box_head">{item.Title}</div>
              </div>
            {/if}
            <div class="box_desc">
              <img
                src="https://www.gffootballplayers.com/{item.Preview_Image
                  .path}"
                width="40%"
                align="left"
                alt="image"
              />
              <br clear="”left”" />
              {@html item.Description}
              {#if item.REFER}
                <a href={item.REFER} target="_blanck">
                  <i class="fas fa-link" />
                  <div class="maxwidth">{item.REFER}</div>
                </a>
              {/if}
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">.inner,.inner .container{width:100%}.inner .container .box{background:#fff;overflow:hidden}.inner .container .box:hover{box-shadow:0 0 1px 5px rgba(61,158,215,.63)}.inner .container .box .box_img{position:relative;width:100%;height:450px;background-position:top;background-size:100% 100%;float:left}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_img{background-size:cover;background-position:50%!important}}.inner .container .box .box_head{width:100%;bottom:0;background:rgba(0,0,0,.5);padding:20px;font-size:23px!important}.inner .container .box .box_head,.inner .container .box .box_time{font-weight:900;position:absolute;color:#f5f5f5;margin:0!important;text-align:center}.inner .container .box .box_time{width:50px;height:100px;left:0;z-index:9;background:linear-gradient(90deg,#4b60db,#0060dd);padding:27px 5px;font-size:15px!important;top:200px}.inner .container .box .box_desc{font-size:16px;overflow:hidden;float:left;overflow-wrap:break-word;width:100%;max-width:1450px;margin:60px;position:relative;left:50%;transform:translateX(-50%);background:#ebecf0;padding:20px;border-radius:10px}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc{font-size:14px;width:90%;margin:16px}}.inner .container .box .box_desc img{margin:25px 25px 25px 0}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc img{margin:22px 10px 10px 0;width:100%}}.fas{float:left}.maxwidth{height:39px;overflow:hidden;background:#faf9f9;border:3px solid #fff;font-weight:999}</style>
