<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let fixtechnic = $Data.fixtechnic[0];
  let maxheight = 0;
</script>

<style lang="scss">.inner,.inner .container{width:100%}.inner .container .box{background:#fff;overflow:hidden}.inner .container .box:hover{box-shadow:0 0 1px 5px rgba(61,158,215,.63)}.inner .container .box .box_img{position:relative;width:100%;height:300px;background-position:50%;background-size:82%;float:left;border-radius:30%;border-top-left-radius:0;border-top-right-radius:0}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_img{background-size:cover}}.inner .container .box .box_desc{text-align:center;font-size:16px;overflow:hidden;float:left;width:97%;overflow-wrap:break-word;margin:20px}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc{font-size:14px;width:90%;margin:16px}}.inner .container .box .box_desc img{margin:25px}@media only screen and (max-width:830px) and (min-width:200px){.inner .container .box .box_desc img{margin:22px 10px 0 0;width:100%}}</style>

<div class="fullWidth inner">

  <div class="item">
    <div class="container">

      <div class="box popup" id="box_0">

        <div
          class="box_img"
          style="background-image:url(https://www.gffootballplayers.com/{fixtechnic.home[0].value.image.path})" />

        <div class="box_desc">
          <h1 class="box_head">{fixtechnic.home[0].value.title}</h1>
          {@html fixtechnic.home[0].value.description}
        </div>

      </div>
    </div>
  </div>

</div>
