<script>
  export let Player, currentframe;
  import { createEventDispatcher } from "svelte";
  import { fade, fly } from "svelte/transition";
  let img;
  const dispatch = createEventDispatcher();
  const close = () => {
    dispatch("Close");
  };
  const next = () => {
    img.classList.remove("appearleft");
    img.classList.remove("appearright");
    setTimeout(() => {
      img.classList.add("appearright");
    }, 10);
    if (currentframe == Player.Photos.length - 1) {
      currentframe = 0;
    } else {
      currentframe++;
    }
  };
  const prev = () => {
    img.classList.remove("appearleft");
    img.classList.remove("appearright");
    setTimeout(() => {
      img.classList.add("appearleft");
    }, 10);
    if (currentframe == 0) {
      currentframe = Player.Photos.length - 1;
    } else {
      currentframe--;
    }
  };
</script>

<style lang="scss">.blackBG{background:rgba(0,0,0,.8);width:100%;height:95vh;position:fixed;left:0;top:5vh;z-index:9999}.modal{width:100%;height:100%;padding:20px;border-radius:22px;text-align:center;display:flex;place-content:center;align-items:center;flex-wrap:wrap;font-weight:999}@media only screen and (max-width:830px) and (min-width:200px){.modal{left:0}}.modal img{max-height:100%}@media only screen and (max-width:830px) and (min-width:200px){.modal img{width:100%}}.modal .fa-caret-left{text-shadow:0 0 5px #000;font-size:65px;color:#fff;position:absolute;left:80px;z-index:5;cursor:pointer}@media only screen and (max-width:830px) and (min-width:200px){.modal .fa-caret-left{left:30px;margin:0}}.modal .fa-caret-right{text-shadow:0 0 5px #000;font-size:65px;color:#fff;position:absolute;right:80px;z-index:5;cursor:pointer}@media only screen and (max-width:830px) and (min-width:200px){.modal .fa-caret-right{right:30px;margin:0}}.modal .fa-times{font-size:35px;color:#fff;position:absolute;top:20px;right:30px;z-index:5;cursor:pointer}</style>

<div class="blackBG fadein" out:fade>
  <div class="modal">
    <i
      class="fas fa-times appearright"
      on:click={() => {
        close();
      }} />
    <i
      class="fas fa-caret-left appearleft"
      on:click={() => {
        prev();
      }} />
    <img
      src="https://www.gffootballplayers.com/{Player.Photos[currentframe].path}"
      alt=""
      class="popup"
      out:fly={{ y: -1000, duration: 600 }}
      bind:this={img} />
    <i
      class="fas fa-caret-right appearright"
      on:click={() => {
        next();
      }} />
  </div>
</div>
