<script>
    import { Link } from "yrv";
    import { Data, get_lang } from "@stores/app_store.js";
    let langs = $Data.langs[0];
    let news = $Data.News.slice().sort((a, b) => b._created - a._created);
    let maxheight = 0;
    const maxheightupdate = (node) => {
        // console.log(node);
        // maxheight += node.clientHeight;
        const ratio = node.clientWidth / node.clientHeight,
            h = node.parentNode.clientWidth / ratio,
            rowSpan = Math.round((h + 9) / (1 + 9));
        node.parentNode.style.gridRowEnd = "span " + rowSpan;
    };
    const getyear = (unixtimestamp) => {
        // Convert timestamp to milliseconds
        var date = new Date(unixtimestamp * 1000);
        // Year
        var year = date.getFullYear();
        return year;
    };

    const getday = (unixtimestamp) => {
        // Convert timestamp to milliseconds
        var date = new Date(unixtimestamp * 1000);
        var day = date.getDate();
        return day;
    };

    const getmonth = (unixtimestamp) => {
        var months_arr = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var date = new Date(unixtimestamp * 1000);
        var month = months_arr[date.getMonth()];
        return month;
    };
</script>

<div class="TopPart">
    <div class="header">{langs.Mnews}</div>
</div>
<div class="fullWidth masonry">
    {#each news as item, i}
        <div class="item">
            <div
                class="container"
                use:maxheightupdate
                on:click={() => {
                    window.open("/news/" + item._id, "_blank");
                }}
            >
                <div class="box popup" id="box_{i}">
                    <div class="box_time">
                        {getday(item._modified)}
                        <br />
                        {getmonth(item._modified)}
                        <br />
                        {getyear(item._modified)}
                    </div>
                    <div
                        class="box_img"
                        style="background-image:url('https://www.gffootballplayers.com/{item
                            .Preview_Image.path}')"
                    >
                        <div class="box_head">{item.Title}</div>
                    </div>

                    <div class="box_desc">
                        {item.ShortDescription}
                        <div class="box_more">{langs.Pmorebutton}</div>
                    </div>
                </div>
            </div>
        </div>
    {/each}
</div>

<style lang="scss">.masonry{margin-top:10px;width:100%}@media only screen and (max-width:6000px) and (min-width:831px){.masonry{display:grid;grid-gap:9px;grid-template-columns:repeat(auto-fill,minmax(32.5%,.3fr));grid-auto-rows:1.5px}}.masonry .container{width:100%}.masonry .container .box{background:#fff;margin:9px;overflow:hidden;cursor:pointer}.masonry .container .box:hover{box-shadow:0 0 1px 5px rgba(61,158,215,.63)}.masonry .container .box .box_img{position:relative;width:100%;height:250px;background-position:50%;background-size:contain;background-repeat:no-repeat;background-color:#16192b;float:left;pointer-events:none}.masonry .container .box .box_head{width:100%;font-weight:900;position:absolute;bottom:0;color:#f5f5f5;background:rgba(0,0,0,.7);margin:0!important;padding:7px;font-size:16px!important}.masonry .container .box .box_time{width:40px;font-weight:900;position:absolute;left:0;top:0;z-index:9;text-align:center;color:#f5f5f5;background:linear-gradient(90deg,#4b60db,#0060dd);margin:0!important;padding:5px;font-size:13px!important}.masonry .container .box .box_desc{font-size:12px;overflow:hidden;float:left;width:97%;overflow-wrap:break-word;margin:5px;pointer-events:none}</style>
