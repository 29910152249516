<script>
  import { Link } from "yrv";
  import { Data, get_lang, detectMob } from "@stores/app_store.js";
  import MenuMob from "./menuMobile.svelte";
  let langs = $Data.langs[0];
  let submenu = $Data.submenu;
</script>

{#if detectMob()}
  <MenuMob />
{:else}
  <div class="menu_wrapper_left">
    {#each langs.LeftMenu as item, i}
      {#if item.value.active}
        {#if item.value.url == "/player"}
          <div class="menuItem dropmenu">
            <Link class="Link" href={item.value.url}>{item.value.name}</Link>
            <div class="submenu">
              <div class="content">
                {#each submenu as item_}
                  {#if item_.active}
                    <a class="Link" href="/player?cat={item_.ID}">
                      <div class="subItem">{item_["name_" + get_lang()]}</div>
                    </a>
                  {:else}
                    <a class="Link" href="/player">
                      <div class="subItem">{item_["name_" + get_lang()]}</div>
                    </a>
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {:else}
          <Link class="Link" href={item.value.url}>
            <div class="menuItem">{item.value.name}</div>
          </Link>
        {/if}
      {/if}
    {/each}
  </div>
  <div class="site-logo-area" />
  <div class="menu_wrapper_right">
    {#each langs.RightMenu as item, i}
      {#if item.value.active}
        <Link class="Link" href={item.value.url}>
          <div class="menuItem">{item.value.name}</div>
        </Link>
      {/if}
    {/each}
  </div>
{/if}

<style lang="scss">.menuItem{text-align:center;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:flex;align-items:center;height:60px;padding:10px;font-size:12px;background:rgba(234,238,244,0);font-weight:999}.menuItem:hover{background:#f5f5f5;color:#1034a6;transition:background 1s}.menuItem :global(a.Link){height:58px;display:flex;align-items:center}.menu_wrapper_left{float:left;display:flex;justify-content:space-between;color:#fff;width:100%;font-size:85%}.menu_wrapper_left :global(a){text-decoration:none;color:#f5f5f5}.site-logo-area{width:300px}.menu_wrapper_right{float:left;display:flex;justify-content:space-between;color:#fff;width:100%}.menu_wrapper_right :global(a){color:#f5f5f5}.dropmenu:hover :global(a){color:#1034a6}.dropmenu:hover .submenu .content{visibility:visible;height:210px!important}.dropmenu:hover .submenu .content :global(a){opacity:1}.submenu{width:170px;position:absolute;z-index:9;display:flex;justify-content:start;top:120px;transform:translateX(-10px);color:#1034a6;background:#e8eef4;font-weight:999}.submenu .content{visibility:hidden;display:flex;width:100%;flex-direction:column;align-items:center;cursor:pointer;height:0;transition:height .5s cubic-bezier(1,.1,.165,1)}.submenu .content :global(a){color:#1034a6;height:60px;padding:10px;width:100%;opacity:0;transition:opacity 2s,background 1s}.submenu .content :global(a):hover{background:#1034a6;color:#f5f5f5}</style>
