<script>
  import { navigateTo } from "yrv";
  import { onMount } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { detectMob } from "@stores/app_store.js";
  import { router } from "yrv";
  import { get_lang } from "@stores/app_store.js";
  export let Player, langs;
</script>

<style lang="scss">.stats{justify-content:center;align-items:center;flex-wrap:wrap;font-size:18px;width:100%;height:100%;background:#f5f5f5;background:linear-gradient(180deg,rgba(43,49,68,0),hsla(0,0%,100%,.5) 58%,rgba(43,49,68,0) 64%,rgba(43,49,68,0))!important;text-align:center;font-weight:100;display:flex}@media only screen and (max-width:830px) and (min-width:200px){.stats{font-size:13px}}.stats h1{width:100%}.stats .wrapper{padding:0 20px;border-right:1px solid #1034a6;line-height:24px}@media only screen and (max-width:830px) and (min-width:200px){.stats .wrapper{width:33%;margin:7px 0;line-height:15px;padding:0 11px}}.stats .wrapper:last-child{border-right:0 solid #fff}.stats .wrapper .top{font-size:18px;font-weight:990}.stats .wrapper .top img{width:100%;height:100px;-o-object-fit:contain;object-fit:contain}@media only screen and (max-width:830px) and (min-width:200px){.stats .wrapper .top{font-size:13px}}</style>

{#if Player && Player.prevTeam && Player.prevTeam.length != 0}
  <div class="stats zoom">
    <h1>{langs.PproigoumenesOmades}</h1>
    {#each Player.prevTeam as item, i}
      <div class="wrapper zoom">
        <div class="top">
          <img
            src="https://www.gffootballplayers.com/{item.value.team.Photo.path}"
            alt={item.value.team.Fullname} />
        </div>
        <div class="bottom">
          {item.value.xronologia}
          <br />
          {item.value.team.Fullname}
        </div>
      </div>
    {/each}
  </div>
{/if}
