<script>
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];

  // Set the date we're counting down to
  let countDownDate = new Date("Jul 1, 2020 02:00:00").getTime();
  let timer = "0";
  // Update the count down every 1 second
  let x = setInterval(function() {
    // Get today's date and time
    let now = new Date().getTime();
    // Find the distance between now and the count down date
    let distance = countDownDate - now;
    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    timer = "" + days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(x);
      timer = "EXPIRED";
    }
  }, 1000);
</script>

<style lang="scss">#COUNTDOWN{z-index:9;width:300px;background:#00a334;color:#fff;padding:15px 29px;font-size:16px;border-radius:22px;transform:scale(.85)}@media only screen and (max-width:830px) and (min-width:200px){#COUNTDOWN{z-index:9;width:100%;background:#00a334;color:#fff;position:absolute;top:124px;display:flex;justify-content:center;margin:-4px;padding:6px 9px;font-size:12.5px;border-radius:0;transform:scale(1)}}#COUNTDOWNtimer{font-size:20px}@media only screen and (max-width:830px) and (min-width:200px){#COUNTDOWNtimer{font-size:13px;margin-left:10px}}</style>

<div id="COUNTDOWN">
  {langs.eventText}
  <!-- <div id="COUNTDOWNtimer">{timer}</div> -->
  <!-- <div id="COUNTDOWNtimer">Start of transfer period</div> -->
</div>
