<script>
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let word;
  setTimeout(() => {
    word.classList.remove("active");
  }, 3000);
  const close = () => {
    word.classList.toggle("active");
  };
</script>

<div class="instractor" on:click={close}>
  <div class="word active" bind:this={word}>
    <div class="close">
      <i class="fas fa-times" />
    </div>
    {@html langs.instractor}
  </div>
  <div class="icon">
    <img
      src="https://www.gffootballplayers.com/images/onlylogo.png"
      loading="lazy"
      alt="logo"
    />
  </div>
</div>

<style lang="scss">.instractor{position:fixed;bottom:0;right:0;width:190px;height:170px;z-index:999}@media only screen and (max-width:830px) and (min-width:200px){.instractor{right:-110px;bottom:-100px}}.word{background:#0083f3;color:#fff;padding:30px;position:absolute;bottom:110px;right:110px;width:360px;transform:scale(0);border-radius:20px;box-shadow:0 0 10px rgba(0,0,0,.42);overflow-wrap:break-word}.word.active{transform:scale(1);-webkit-animation:popballon .2s;animation:popballon .2s}.word.active .close{position:absolute;z-index:11;color:#fff;background:#0083f3;padding:10px;text-align:center;border-radius:50%;width:40px;top:-10px;right:0}.icon img{width:120px;height:120px;transform:scale(.6);border-radius:50%;-webkit-animation:eyetracker 1.5s ease-in-out infinite;animation:eyetracker 1.5s ease-in-out infinite}@-webkit-keyframes popballon{0%{transform:scale(0) translateX(0) translateY(200px);box-shadow:0 0 2px 1px #078ee9}to{transform:scale(1) translateX(0) translateY(0);box-shadow:0 0 2px 20px rgba(7,97,231,0)}}@keyframes popballon{0%{transform:scale(0) translateX(0) translateY(200px);box-shadow:0 0 2px 1px #078ee9}to{transform:scale(1) translateX(0) translateY(0);box-shadow:0 0 2px 20px rgba(7,97,231,0)}}@-webkit-keyframes eyetracker{0%{transform:scale(.6);box-shadow:0 0 2px 1px #078ee9}50%{transform:scale(.65)}to{transform:scale(.6);box-shadow:0 0 2px 20px rgba(7,97,231,0)}}@keyframes eyetracker{0%{transform:scale(.6);box-shadow:0 0 2px 1px #078ee9}50%{transform:scale(.65)}to{transform:scale(.6);box-shadow:0 0 2px 20px rgba(7,97,231,0)}}</style>
